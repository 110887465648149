import Category from '../interfaces/Category';
import languageData, { Languages } from '../languages';

const nameTranslations: Languages<string> = {
	Default: 'All',
	Estonian: 'Kõik', // This is from Google translate so double check with a translator if this is correct
	Arabic: 'الكل',
	ChineseMainland: '全部',
	ChineseTaiwan: '全部',
	Dutch: 'Alle',
	French: 'Tout',
	Italian: 'Tutto',
	Japanese: 'すべて',
	Korean: '전체',
	Latvian: 'Visi',
	Lithuanian: 'Visi',
	Polish: 'Wszystkie',
	PortugueseBrazilian: 'Tudo',
	PortugueseEuropean: 'Tudo',
	Russian: 'Все',
	Spanish: 'Todo',
	Turkish: 'AllTR', // Change when there's a translator
};

const allCategory: (locale: string) => Category = (locale) => {
	let name = nameTranslations.Default;

	Object.keys(languageData).forEach((language) => {
		if (languageData[language]?.locale === locale) {
			const nameTranslation = nameTranslations[language];

			if (nameTranslation) name = nameTranslation;
		}
	});

	return {
		id: -1,
		path: '',
		locale,
		name,
	};
};

export default allCategory;
