/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/heading-has-content */
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { ContentImage } from 'src/interfaces/PostData';
import * as style from './ContentComponents.module.scss';

const img: (contentImages: ContentImage[], src?: string, alt?: string) => JSX.Element | null = (
	contentImages,
	src,
	alt
) => {
	const fileIndex = contentImages.findIndex((contentImage) => {
		return src && contentImage.url.endsWith(src);
	});

	// Failed to load/render image
	if (fileIndex === -1) return null;

	const image = getImage(contentImages[fileIndex]);
	if (!image) {
		// Failed to find data for GatsbyImage, but the image can be displayed without gatsby-plugin-image
		// E.g. .gif files, because gatsby-transformer-sharp doesn't support them
		return <img src={contentImages[fileIndex].publicURL} alt={alt || ''} className={style.img} />;
	}

	return <GatsbyImage image={image} alt={alt || ''} className={style.img} />;
};

const p: (props: any) => JSX.Element = (props) => {
	const element = props.children[0];
	return element?.type?.name === 'img' ? { ...element } : <p className={style.p}>{props.children}</p>;
};

const h2: (props: any) => JSX.Element = (props) => {
	return <h2 className={style.h2}>{props.children}</h2>;
};

const a: (props: any) => JSX.Element = (props) => {
	const href = props.href as string;
	const ourDomain = 'https://blog.spectrocoin.com';

	// Our domain, no need to open in a new window
	if (href.startsWith(ourDomain)) {
		const updatedHref = href.replace(ourDomain, '');
		return (
			<Link to={updatedHref} className={style.a}>
				{props.children}
			</Link>
		);
	}

	if (!href.startsWith('http')) {
		return (
			<a href={href} className={style.a}>
				{props.children}
			</a>
		);
	}

	// Open external link in new window
	return (
		<a href={href} className={style.a} target="_blank" rel="noopener noreferrer">
			{props.children}
		</a>
	);
};

const ul: (props: any) => JSX.Element = (props) => {
	return <ul className={style.list}>{props.children}</ul>;
};

const ol: (props: any) => JSX.Element = (props) => {
	return <ol className={style.list}>{props.children}</ol>;
};

export const getNonPostSpecificContentComponents = () => {
	return {
		p,
		h2,
		a,
		ul,
		ol,
	};
};

const getContentComponents = (contentImages: ContentImage[]) => {
	return {
		img: ({ ...imgProps }) => img(contentImages, imgProps.src, imgProps.alt),
		...getNonPostSpecificContentComponents(),
	};
};

export default getContentComponents;
