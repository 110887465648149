import { Link } from 'gatsby';
import React from 'react';
import arrow from 'src/assets/images/vector/arrow-right.svg';
import * as style from './LinkWithArrow.module.scss';

interface Props {
	text: JSX.Element | string;
	href: string;
}

const LinkWithArrow: React.FC<Props> = ({ text, href }) => {
	if (href.slice(0, 4) === 'http')
		return (
			<div className={style.arrowContainer}>
				<a href={href} className="link">
					{text}
					<img src={arrow} className={style.arrowRight} />
				</a>
			</div>
		);

	return (
		<div className={style.arrowContainer}>
			<Link to={href} className="link">
				{text}
			</Link>
			<svg
				width="20px"
				height="20px"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
				className={style.arrowRight}
			>
				<polygon points="16.172 9 10.101 2.929 11.515 1.515 20 10 19.293 10.707 11.515 18.485 10.101 17.071 16.172 11 0 11 0 9" />
				<path fill="red" />
			</svg>
		</div>
	);
};

export default LinkWithArrow;
