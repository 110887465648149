/* eslint-disable no-param-reassign */
export const getPostDate = (postDate: string, locale: string): string => {
	const today = new Date();
	const createdDate = Date.parse(postDate);
	let date = new Date(createdDate);
	if (date > today) date = today;

	const monthFormat = new Intl.DateTimeFormat(locale, { month: 'long' });
	const month = monthFormat.format(createdDate);
	return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};

// Ignores markdown links and shortens the given string
export const shortenExcerpt = (string: string, length: number): string => {
	const linkRegEx = RegExp(/\[([^[\]]*)\]\((.*?)\)/gm);
	const links = string.match(linkRegEx);

	if (links) {
		const token = '<LINK>'; // Can be any used string (best if not commonly used)
		const replaced = string.replace(linkRegEx, token);
		const splits = replaced.split(token);
		let result = '';
		let textLength = 0;
		for (const split of splits) {
			if (textLength === length) return `${result}...`;

			if (textLength + split.length > length) {
				const substring = split.substring(0, length - textLength);
				result += `${substring}...`;
				return result;
			}
			result += split;
			textLength += split.length;

			const linkToParse = links.shift();
			if (linkToParse) {
				const singleMatch = /\[([^[]+)\]\((.*)\)/;
				const link = singleMatch.exec(linkToParse);
				if (link) {
					const linkText = link[1];
					const linkUrl = link[2];

					if (textLength + linkText.length > length) {
						const substring = linkText.substring(0, length - textLength);
						result += `[${substring}...](${linkUrl})`;
						return result;
					}
					result += `[${linkText}](${linkUrl})`;
					textLength += linkText.length;
				}
			}
		}

		return result;
	}

	const substring = string.substring(0, length);
	return substring.length === string.length ? string : `${substring}...`;
};
