import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import getContentComponents, {
	getNonPostSpecificContentComponents,
} from 'src/components/contentComponents/ContentComponents';
import { ContentImage } from 'src/interfaces/PostData';

interface Props {
	contentImages?: ContentImage[];
	className?: string;
}

const MarkdownRenderer: React.FC<Props> = ({ children, contentImages, className }) => {
	const content = children as string;
	const components = contentImages ? getContentComponents(contentImages) : getNonPostSpecificContentComponents();
	return (
		<ReactMarkdown components={components} className={className} skipHtml={false} rehypePlugins={[rehypeRaw]}>
			{content}
		</ReactMarkdown>
	);
};

export default MarkdownRenderer;
