import React from 'react';
import { Helmet } from 'react-helmet';

export interface PageMetaData {
	title?: string;
	description?: string;
	keywords?: string;
	robots?: string;
	canonical?: string;
	og?: {
		title?: string;
		description?: string;
		image?: string;
		url?: string;
		urlPathname?: string; // Used to construct og.url
	};
}

const defaultPageMetaData: PageMetaData = {
	title: 'SpectroCoin Blog',
	description: 'SpectroCoin Blog description',
	og: {
		image: `${process.env.GATSBY_BASE_URL}/og/default.png`,
	},
};

const MetaTags: React.FC<{ data: PageMetaData }> = ({ data }) => {
	const mergedPageMetaData: PageMetaData = {
		...data,
		title: data.title || defaultPageMetaData.title,
		description: data.description || defaultPageMetaData.description,
		og: {
			title: data.og?.title || data.title || defaultPageMetaData.title,
			description: data.og?.description || data.description || defaultPageMetaData.description,
			image:
				(data.og?.image && `${process.env.GATSBY_BASE_URL}${data.og?.image}`) || defaultPageMetaData.og?.image,
			url:
				data.og?.url ||
				(data.og?.urlPathname && `${process.env.GATSBY_BASE_URL}${data.og?.urlPathname}`) ||
				data.canonical,
		},
	};

	return (
		<Helmet>
			<title>{mergedPageMetaData.title}</title>
			<meta name="description" content={mergedPageMetaData.description} />

			{mergedPageMetaData.keywords && <meta name="keywords" content={mergedPageMetaData.keywords} />}
			{mergedPageMetaData.robots && <meta name="robots" content={mergedPageMetaData.robots} />}
			{mergedPageMetaData.canonical && <link rel="canonical" href={mergedPageMetaData.canonical} />}

			<meta property="og:title" content={mergedPageMetaData.og?.title} />
			<meta property="og:description" content={mergedPageMetaData.og?.description} />
			<meta property="og:image" content={mergedPageMetaData.og?.image} />
			{mergedPageMetaData.og?.url && <meta property="og:url" content={mergedPageMetaData.og?.url} />}
			<meta name="twitter:card" content="summary_large_image" />
		</Helmet>
	);
};

export default MetaTags;
