import languageData from '../languages'; // Relative, because used in gatsby-node.ts

// Make sure this list does not go out of date (if you add/remove languages or something in SC landing locales changes)
const differentLocales = [
	{
		strapi: 'zh-Hant-TW',
		ui: languageData.ChineseTaiwan?.locale,
	},
];

// There are some inconsistencies between strapi CMS locales and our project
// So this helper function helps to convert from strapi locale to our project locale
const fromStrapiToUI = (strapiLocale: string): string => {
	return differentLocales.find((l) => l.strapi === strapiLocale)?.ui || strapiLocale;
};

const fromUItoStrapi = (uiLocale: string): string => {
	return differentLocales.find((l) => l.ui === uiLocale)?.strapi || uiLocale;
};

export { fromUItoStrapi, fromStrapiToUI };
